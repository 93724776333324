<template>
  <!-- 企业主页 -->
  <div class="contentBox comBg">
    <section>
      <!-- 背景墙 -->
      <div class="resumeTitle clearfix boxShadow">
        <!-- 头像 -->
        <div class="block talentAvd">
          <el-image
            style="width: 100px; height: 100px"
            :src="circleUrl"
          ></el-image>
        </div>
        <!-- 信息 -->
        <div class="talentInfo">
          <div class="contact">
            <span class="name">{{ staffInfo.u_name }}</span>
            <span>
              <img
                :class="staffInfo.u_phone === '' ? 'gary' : ''"
                :src="telIcon"
                alt=""
              />
            </span>
            <span>
              <img
                :class="staffInfo.eamail_auth == '0' ? 'gary' : ''"
                :src="emailIcon"
                alt=""
              />
            </span>
            <span>
              <img
                :class="staffInfo.qq === '' ? 'gary' : ''"
                :src="qqIcon"
                alt=""
              />
            </span>
          </div>
          <div class="baseInfo">
            <span>
              {{ staffInfo.introduction }}
            </span>
          </div>
        </div>
        <!-- 分享 -->
        <div class="talentTel">
          <p class="shellBox" style="color: #fff;">
            分享至:
            <el-popover
              placement="bottom"
              title="打开微信扫一扫"
              width="210"
              trigger="click"
            >
              <div
                id="qrcode"
                ref="qrcode"
                style="text-align: center;display: block ruby;"
              ></div>
              <img
                slot="reference"
                class="mini-icon iconHover"
                :src="wxIcon"
                alt=""
              />
            </el-popover>

            <img
              class="mini-icon iconHover"
              :src="qqIcon"
              @click="share('qq')"
              alt=""
            />
            <img
              class="mini-icon iconHover"
              :src="wbIcon"
              @click="share('sina')"
              alt=""
            />
            <img
              class="mini-icon iconHover"
              :src="kjIcon"
              @click="share('qzone')"
              alt=""
            />
          </p>
        </div>
      </div>
      <div class="showBox">
        <el-row :gutter="16">
          <el-col :span="16">
            <el-card class="box-card">
              <div slot="header">
                <span class="cardTitle">运作的服务</span>
              </div>
              <div class="tabBoxn">
                <el-tabs
                  v-model="activeName"
                  type="card"
                  @tab-click="serveTypeSwitch"
                >
                  <el-tab-pane label="全部" name="first">
                    <p class="padd-tb-8">
                      正在运作的兼职（{{ runNum.par_job_count }}个）
                    </p>
                    <p class="padd-tb-8">
                      正在运作的全职（{{ runNum.full_job_count }}个）
                    </p>
                    <p class="padd-tb-8">
                      正在运作的人才（{{ runNum.resume_par }}个）
                    </p>
                    <!-- <p class="padd-tb-8">
                      正在运作的资质（{{ runNum.qualifications }}个）
                    </p> -->
                  </el-tab-pane>
                  <el-tab-pane label="兼职" name="second"> </el-tab-pane>
                  <el-tab-pane label="全职" name="third"> </el-tab-pane>
                  <el-tab-pane label="人才" name="fourth"> </el-tab-pane>
                  <!-- <el-tab-pane label="资质" name="five"> </el-tab-pane> -->
                </el-tabs>

                <!-- 运作信息 -->
                <div v-if="activeName !== 'first'">
                  <el-form :model="searchInfo">
                    <el-row :gutter="16">
                      <el-col :span="6">
                        <el-select
                          v-model="searchInfo.j_certificate"
                          @change="getCerMajor"
                          placeholder="选择分类"
                          clearable
                        >
                          <el-option
                            v-for="(item, index) in selCerList"
                            :key="index"
                            :label="item.certificate"
                            :value="item.certificate"
                          >
                          </el-option>
                        </el-select>
                      </el-col>
                      <el-col :span="6">
                        <el-select
                          v-model="searchInfo.j_major"
                          placeholder="选择专业"
                          clearable
                        >
                          <el-option
                            v-for="(item, index) in cerChildList"
                            :key="index"
                            :label="item.certificate"
                            :value="item.certificate"
                          >
                          </el-option>
                        </el-select>
                      </el-col>
                      <el-col :span="8" v-if="workSearch">
                        <el-cascader
                          size="large"
                          :options="comcity"
                          :props="{ checkStrictly: true }"
                          v-model="selectedOptions"
                          @change="setUseInfoSocial"
                          placeholder="选择省份或城市"
                          style="width: 100%;"
                          clearable
                        >
                        </el-cascader>
                      </el-col>
                      <el-col :span="8" v-if="talSearch">
                        <el-cascader
                          size="large"
                          :options="comcity"
                          :props="{ checkStrictly: true }"
                          v-model="selectedOptions"
                          @change="setuserInfoCity"
                          placeholder="选择省份或城市"
                          style="width: 100%;"
                          clearable
                        >
                        </el-cascader>
                      </el-col>
                      <!-- 兼职 -->
                      <el-col :span="4" v-if="activeName == 'second'">
                        <el-button
                          type="primary"
                          v-prevent-repeat-click
                          @click="getjianzhi"
                          class="radius-25"
                          >搜索</el-button
                        >
                      </el-col>
                      <!-- 全职 -->
                      <el-col :span="4" v-if="activeName == 'third'">
                        <el-button
                          type="primary"
                          v-prevent-repeat-click
                          @click="getquanzhi"
                          class="radius-25"
                          >搜索</el-button
                        >
                      </el-col>
                      <!-- 人才 -->
                      <el-col :span="4" v-if="activeName == 'fourth'">
                        <el-button
                          type="primary"
                          v-prevent-repeat-click
                          @click="getrencai"
                          class="radius-25"
                          >搜索</el-button
                        >
                      </el-col>
                    </el-row>
                  </el-form>
                  <!-- 信息展示 -->
                  <div class="showInfoBox">
                    <el-row :gutter="16">
                      <el-col
                        :span="8"
                        v-for="(item, index) in showInfoList"
                        :key="index"
                      >
                        <div class="workInfo">
                          <p class="cerName">
                            <span v-if="activeName == 'fourth'">
                              {{ item.certificate }}-{{ item.major }}
                            </span>
                            <span v-if="activeName != 'fourth'">
                              {{ item.j_certificate }}-{{ item.j_major }}
                            </span>
                          </p>
                          <p class="cerCity">
                            <span v-if="activeName == 'fourth'">
                              {{ item.region_province }}-{{ item.region_city }}
                            </span>
                            <span v-if="activeName != 'fourth'">
                              {{ item.j_area_province }}-{{ item.j_area_city }}
                            </span>
                          </p>
                          <p class="timePrice clearfix">
                            <span>
                              {{ item.create_time.match(/(\S*) /)[1] }}
                            </span>
                            <span>
                              {{ item.zs_price }}
                            </span>
                          </p>
                        </div>
                      </el-col>
                    </el-row>
                  </div>
                  <!-- 页码 -->
                  <div class="pageBox" v-if="activeName == 'second'">
                    <el-pagination
                      background
                      hide-on-single-page
                      @size-change="sizeChange1"
                      @current-change="currentChange1"
                      :current-page="currentPage"
                      :page-sizes="[9, 21, 33]"
                      :page-size="pagesize"
                      layout="total, sizes, prev, pager, next, jumper"
                      :total="tableTotal"
                    >
                    </el-pagination>
                  </div>
                  <div class="pageBox" v-if="activeName == 'third'">
                    <el-pagination
                      background
                      hide-on-single-page
                      @size-change="sizeChange2"
                      @current-change="currentChange2"
                      :current-page="currentPage"
                      :page-sizes="[9, 21, 33]"
                      :page-size="pagesize"
                      layout="total, sizes, prev, pager, next, jumper"
                      :total="tableTotal"
                    >
                    </el-pagination>
                  </div>
                  <div class="pageBox" v-if="activeName == 'fourth'">
                    <el-pagination
                      background
                      hide-on-single-page
                      @size-change="sizeChange3"
                      @current-change="currentChange3"
                      :current-page="currentPage"
                      :page-sizes="[9, 21, 33]"
                      :page-size="pagesize"
                      layout="total, sizes, prev, pager, next, jumper"
                      :total="tableTotal"
                    >
                    </el-pagination>
                  </div>
                </div>
              </div>
            </el-card>
          </el-col>
          <!-- 右侧信息 -->
          <el-col :span="8">
            <el-card class="box-card">
              <div slot="header">
                <span class="cardTitle">基本信息</span>
              </div>
              <div class="buildBox margin-bottom-16">
                <p>
                  <img class="mini-icon" :src="com" alt="" />
                  <span> 公司名称：{{ comBaseInfo.com_external_name }} </span>
                </p>
                <p>
                  <img class="mini-icon" :src="map" alt="" />
                  <span>
                    公司地址：{{ staffInfo.city }}
                    {{ staffInfo.address }}
                  </span>
                </p>
              </div>
              <div class="margin-bottom-16">
                <el-row class="box-bg box-line1">
                  <el-col :span="12" class="comnumbox">
                    <p>最近登录</p>
                    <p class="font-blue">{{ staffInfo.u_login_time }}</p>
                  </el-col>
                  <el-col :span="12" class="comnumbox">
                    <p>简历反馈率</p>
                    <p class="font-red">{{ staffInfo.resume_rate }}%</p>
                  </el-col>
                </el-row>
                <el-row class="box-bg box-line2">
                  <el-col :span="12" class="comnumbox">
                    <p>最近7天运作人才</p>
                    <p class="font-orange">{{ staffInfo.resume_par }}</p>
                  </el-col>
                  <el-col :span="12" class="comnumbox">
                    <p>最近7天运作职位</p>
                    <p class="font-green">
                      {{ staffInfo.par_job_count + staffInfo.full_job_count }}
                    </p>
                  </el-col>
                </el-row>
              </div>
              <div class="margin-bottom-16">
                <el-form class="falseForm">
                  <el-form-item label="公司简介:" label-width="75px">
                    <span>{{ comBaseInfo.com_synopsis }}</span>
                  </el-form-item>
                  <el-form-item label="专业擅长:" label-width="75px">
                    <div>
                      {{ staffInfo.expert_in }}
                      <!-- <span
                        v-for="(item, index) in staffInfo.expert_in.split(',')"
                        :key="index"
                        >{{ item }}</span
                      > -->
                    </div>
                  </el-form-item>
                </el-form>
              </div>
              <el-row class="botoomBox">
                <el-col :span="12">
                  <p>资料完善度</p>
                  <p class="font-orange">{{ staffInfo.perfect_rate }}%</p>
                </el-col>
                <el-col :span="12">
                  <p>活跃度</p>
                  <p class="font-orange">{{ staffInfo.activity_rate }}%</p>
                </el-col>
              </el-row>
            </el-card>
          </el-col>
        </el-row>
      </div>
      <comInfo></comInfo>
    </section>
  </div>
</template>
<script>
import { mapState, mapMutations } from "vuex";
import QRCode from "qrcodejs2";
import $ from "jquery";
import comInfo from "@/view/comPage/comInfo.vue";
import { provinceAndCityData, CodeToText } from "element-china-area-data";
export default {
  components: {
    comInfo,
  },
  data() {
    return {
      circleUrl: require("@/assets/icon/avatar.png"),
      phoneIcon: require("@/assets/icon/phone.png"),
      kjIcon: require("@/assets/icon/shell-kj.png"),
      qqIcon: require("@/assets/icon/shell-qq.png"),
      wxIcon: require("@/assets/icon/shell-wx.png"),
      wbIcon: require("@/assets/icon/shell-wb.png"),
      telIcon: require("@/assets/icon/tel.png"),
      emailIcon: require("@/assets/icon/email.png"),
      com: require("@/assets/icon/com.png"),
      map: require("@/assets/icon/map.png"),
      activeName: "first",
      comcity: provinceAndCityData,
      selectedOptions: [],
      staffInfo: {},
      comBaseInfo: {},
      runNum: {},
      searchInfo: {},
      selCerList: [],
      cerChildList: [],
      showInfoList: [],
      workSearch: true,
      talSearch: false,
      currentPage: 1,
      pagesize: 9,
      tableTotal: 0,
    };
  },
  computed: {
    ...mapState(["userInfo", "comRealeInfo", "comStaffInfo", "comReale"]),
  },
  watch: {
    comStaffInfo() {
      this.circleUrl = this.comStaffInfo.u_portrait_url;
    },
    comRealeInfo() {
      this.getReale();
    },
  },
  methods: {
    ...mapMutations([]),

    sizeChange1(data) {
      this.pagesize = data;
      this.currentPage = 1;
      this.getjianzhi(this.currentPage, this.pagesize);
    },
    currentChange1(data) {
      this.currentPage = data;
      this.getjianzhi(data, this.pagesize);
    },
    sizeChange2(data) {
      this.pagesize = data;
      this.currentPage = 1;
      this.getquanzhi(this.currentPage, this.pagesize);
    },
    currentChange2(data) {
      this.currentPage = data;
      this.getquanzhi(data, this.pagesize);
    },
    sizeChange3(data) {
      this.pagesize = data;
      this.currentPage = 1;
      this.getrencai(this.currentPage, this.pagesize);
    },
    currentChange3(data) {
      this.currentPage = data;
      this.getrencai(data, this.pagesize);
    },
    // 证书下拉
    getCerList() {
      this.$http.post("index/certificate/certificate").then((res) => {
        this.selCerList = res.data.resultData;
      });
    },
    // 获取证书专业
    getCerMajor(e) {
      this.selCerList.forEach((element) => {
        if (element.certificate == e) {
          this.cerChildList = element.child;
          this.$set(this.searchInfo, "j_major", "");
        }
      });
    },
    // 设置省市
    setUseInfoSocial() {
      this.searchInfo.province = CodeToText[this.selectedOptions[0]];
      this.searchInfo.city = CodeToText[this.selectedOptions[1]];
    },
    setuserInfoCity() {
      this.searchInfo.expected_province = CodeToText[this.selectedOptions[0]];
      this.searchInfo.expected_city = CodeToText[this.selectedOptions[1]];
    },
    // 切换运作服务类型
    serveTypeSwitch(e) {
      if (e.name == "second") {
        // 兼职
        this.searchInfo = {};
        this.selectedOptions = [];
        this.workSearch = true;
        this.talSearch = false;
        this.currentPage = 1;
        this.getjianzhi();
      }
      if (e.name == "third") {
        // 全职
        this.searchInfo = {};
        this.selectedOptions = [];
        this.workSearch = true;
        this.talSearch = false;
        this.currentPage = 1;
        this.getquanzhi();
      }
      if (e.name == "fourth") {
        // 人才
        this.searchInfo = {};
        this.selectedOptions = [];
        this.workSearch = false;
        this.talSearch = true;
        this.currentPage = 1;
        this.getrencai();
      }
    },
    // 兼职
    getjianzhi(page, limit) {
      if (page == undefined || limit == undefined) {
        this.searchInfo.page = 1;
        this.searchInfo.limit = 9;
      } else {
        this.searchInfo.page = this.currentPage;
        this.searchInfo.limit = this.pagesize;
      }
      this.searchInfo.type = 1;
      this.$http.post("company/user/postion", this.searchInfo).then((res) => {
        if (res.data.resultCode === 1) {
          console.log(res.data.resultData);
          this.showInfoList = res.data.resultData.list;
          this.tableTotal = parseInt(res.data.resultData.count);
        }
      });
    },
    // 全包
    getquanzhi() {
      this.searchInfo.type = 2;
      this.$http.post("company/user/postion", this.searchInfo).then((res) => {
        if (res.data.resultCode === 1) {
          console.log(res.data.resultData);
          this.showInfoList = res.data.resultData.list;
          this.tableTotal = parseInt(res.data.resultData.count);
        }
      });
    },
    // 运作的人才
    getrencai() {
      this.$http.post("company/user/talent", this.searchInfo).then((res) => {
        if (res.data.resultCode === 1) {
          console.log(res.data.resultData);
          this.showInfoList = res.data.resultData.list;
          this.tableTotal = parseInt(res.data.resultData.count);
        }
      });
    },
    // 判断认证
    getReale() {
      this.$http
        .post("company/index/complete", { com_id: this.comRealeInfo.id })
        .then((res) => {
          if (res.data.resultCode === 1) {
            console.log(res.data.resultData);
            if (res.data.resultData.flag) {
              this.getHomeInfo(1);
            } else {
              this.getHomeInfo(0);
            }
          }
        });
    },
    // 企业信息
    getHomeInfo(reale) {
      let info = {
        com_auth: reale,
        com_id: this.comRealeInfo.id,
        uid: this.userInfo.uid,
      };
      this.$http.post("company/user/headhunt", info).then((res) => {
        if (res.data.resultCode === 1) {
          if (res.data.resultData.length == 0) {
            this.$message.warning(
              "您的个人信息还未完善，需要先去前往完善个人信息"
            );
            setTimeout(() => {
              this.$router.push("/comPage/personal");
            }, 1500);
          }
          this.staffInfo = res.data.resultData[0];
          this.comBaseInfo = res.data.resultData[0].com_info[0];
        }
      });
    },
    // 全部的运作
    getAllRunning() {
      this.$http.post("company/user/allrun").then((res) => {
        if (res.data.resultCode === 1) {
          this.runNum = res.data.resultData;
        }
      });
    },
    // 微信分享
    weChatShare() {
      $("#qrcode").html("");
      setTimeout(() => {
        let url = window.location.href;
        new QRCode("qrcode", {
          width: 200, // 二维码宽度
          height: 200, // 二维码高度
          text: url, // 地址
        });
      }, 500);
    },
    // 分享
    share(type) {
      //qq空间分享接口
      if (type == "qzone") {
        window.open(
          "https://sns.qzone.qq.com/cgi-bin/qzshare/cgi_qzshare_onekey?url=" +
            document.location.href +
            "?sharesource=qzone&title=建搜搜&pics=http://uploads-crm-51yixiang.oss-cn-shanghai.aliyuncs.com/zpw_profile/2021/20210621_093125.png&summary=建搜搜"
        );
      }
      //新浪微博接口的传参
      if (type == "sina") {
        window.open(
          "http://service.weibo.com/share/share.php?url=" +
            document.location.href +
            "?sharesource=weibo&title=标题&pic=图片&appkey=微博平台申请的key"
        );
      }
      //qq好友接口的传参
      if (type == "qq") {
        window.open(
          "http://connect.qq.com/widget/shareqq/index.html?url=" +
            document.location.href +
            "?sharesource=qzone&title=标题&pics=图片地址&summary=描述"
        );
      }
    },
  },
  mounted() {
    this.getAllRunning();
    this.getCerList();
    this.getCerMajor();
    this.weChatShare();
  },
};
</script>
<style scoped>
.boxShadow {
  background: url("../../../assets/img/comhomeBG.png") no-repeat;
  background-size: cover;
  background-position-y: 80%;
}
.talentInfo span,
.talentInfo p {
  color: #fff;
}
.resumeTitle {
  padding-top: 80px;
  border-bottom: 1px solid #e8e8e8;
  margin-bottom: 20px;
}
.talentIndex {
  padding-top: 24px;
}
.demonstration {
  margin-top: 10px;
}
.talentAvd {
  display: block;
  float: left;
}
.block.talentAvd {
  padding: 20px;
}
.talentInfo {
  display: block;
  float: left;
  width: calc(100% - 490px);
  padding: 28px 0px;
}
.talentInfo .name {
  font-size: 16px;
  display: inline-block;
}
.contact > span {
  padding-right: 20px;
}
.baseInfo {
  margin-top: 14px;
}
.talentInfo .baseInfo span {
  display: inline-block;
  padding: 0px 16px;
}
.talentInfo .baseInfo span:first-child {
  padding-left: 0;
}
.contact {
  margin-top: 14px;
}
.contact > span {
  padding: 0px 0;
  display: inline-block;
}
.contact span img {
  position: relative;
  top: 2px;
  padding-right: 6px;
}
.contact > span:first-child {
  padding-right: 20px;
}
.talentTel {
  float: right;
  padding: 20px;
  text-align: right;
  margin-top: 70px;
}
.shellBox img {
  padding: 0px 4px;
}
.comnumbox p:last-child {
  height: 40px;
  box-sizing: border-box;
}
/* card卡片 */
.cardTitle {
  font-size: 17px;
  font-weight: bold;
  color: #2674fc;
}
.cardTitle:after {
  width: 30px;
  height: 2px;
  display: block;
  background-color: #2674fc;
}
.buildBox p {
  padding: 5px 0;
}
.box-bg {
  background: rgba(37, 115, 241, 0.1);
  border-radius: 6px;
}
.box-bg div {
  padding: 8px 12px;
}
.box-line1 div:first-child {
  border-right: 1px solid #e8e8e8;
  border-bottom: 1px solid #e8e8e8;
}
.box-line1 div:last-child {
  border-bottom: 1px solid #e8e8e8;
}
.box-line2 div:first-child {
  border-right: 1px solid #e8e8e8;
}
.box-bg div p:first-child {
  padding: 8px 0;
  color: #666666;
}
.box-bg div p:last-child {
  font-size: 18px;
  font-weight: bold;
  padding: 8px 0;
  text-align: center;
}
.botoomBox {
  border-top: 1px solid #e8e8e8;
  text-align: center;
}
.botoomBox div {
  padding: 20px;
}
.botoomBox div p:first-child {
  margin-bottom: 10px;
  color: #666;
}
.botoomBox div p:last-child {
  font-size: 18px;
  font-weight: bold;
}
/* .botoomBox div:first-child {
  border-right: 1px solid #e8e8e8;
} */
</style>
<style lang="css">
.tabBoxn .el-tabs .el-tabs__item {
  margin-right: 16px;
  border: none;
  background: rgba(37, 115, 241, 0.1);
  border-radius: 25px;
}
.tabBoxn .el-tabs__item:hover {
  /* color: #fff; */
  /* background-color: #2674fc; */
  border: none;
}
.tabBoxn .el-tabs--card > .el-tabs__header .el-tabs__nav,
.tabBoxn .el-tabs--card > .el-tabs__header {
  border: none;
}
.tabBoxn .el-tabs .el-tabs__item.is-active {
  color: #fff;
  background-color: #2674fc;
  border: none;
}
.tabBoxn .el-tabs--card > .el-tabs__header .el-tabs__item {
  transition: none;
}
.showInfoBox {
  padding-top: 20px;
  padding-bottom: 20px;
}
.workInfo {
  border: 1px solid #e8e8e8;
  border-radius: 5px;
  padding: 12px 20px;
  margin-bottom: 16px;
}
.workInfo .cerName {
  font-size: 16px;
  color: #333;
  padding-bottom: 5px;
}
.workInfo .cerCity {
  font-size: 14px;
  color: #666;
  padding-bottom: 5px;
}
.workInfo .timePrice {
  font-size: 12px;
}
.timePrice span:first-child {
  float: left;
  color: #2674fc;
}
.timePrice span:last-child {
  float: right;
  color: orange;
}
</style>
